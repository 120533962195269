<template>
  <div class="verification">
    <!-- <h4>verification</h4>
    <div class="verify-option">
      <div class="option" :class="driverLicence ? 'success' : 'pending'">
        <figure>
          <img :src="require('@assets/images/licence.png')" alt="" />
        </figure>
        <h5>Driver’s Licence</h5>
      </div>
      <div class="option" :class="police ? 'success' : 'pending'">
        <figure>
          <img :src="require('@assets/images/policy.png')" alt="" />
        </figure>
        <h5>Police Check</h5>
      </div>
      <div class="option" :class="abn ? 'success' : 'pending'">
        <figure>
          <img :src="require('@assets/images/abn.png')" alt="" />
        </figure>
        <h5>ABN</h5>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Verification',
  props: ['verificationData'],
  computed: {
    abn() {
      return Object.keys(this.verificationData.abn).length > 0;
    },
    // police() {
    //   return Object.keys(this.verificationData.police_check).length > 0;
    // },
    // driverLicence() {
    //   return (
    //     Object.keys(this.verificationData.driver_licence_back).length > 0
    //     && Object.keys(this.verificationData.driver_licence_expire_date).length > 0
    //     && Object.keys(this.verificationData.driver_licence_front).length > 0
    //   );
    // },
  },
};
</script>

<style></style>
